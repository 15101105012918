/* Notification.css */
.notification {
    position: fixed;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.75);
    color: white;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    opacity: 1;
    transform: translateY(0);
    z-index: 9999; /* Add a high z-index to ensure it appears above all other layers */
  }
  
  .notification.hidden {
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none; /* Prevent interaction after hiding */
  }
  
  .icon {
    font-size: 16px;
    color: rgb(0, 187, 0);
  }
  
  .message {
    font-size: 14px;
  }
  