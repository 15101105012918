body {
  background-color: #fff!important;
}

.App{
  font-family:sans-serif; 
}

.grid-container {
  display: grid;
  grid-template-columns: 4fr 6fr; /* 1st column fixed, 2nd and 3rd columns fill remaining space */
  gap: 1px;
  width: 100%;
  height: calc(100vh - 70px); 
}

.fixed-column {
  background-color: white;
  border-right: 1px solid #ddd;
}

.menu-with-customisation-column {
  display: grid;
  grid-template-columns: 100px 1fr;
  height: 100%;
}

.auto-column {
  background-color: white;
}

.preview-column {
  background-color: #f6f6f6;
  align-self: end;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.email-container {
  width: 80%;
}

.new-message {
  background-color: #eeeeee;
  color: #333;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.items-container {
  display: flex;
  flex-direction: column;
}

.line-item {
  background-color: #eeeeee;
  margin: 8px 0;
  height: 12px;
}

.line-item-small {
  width: 30%;
}

.line-item-medium {
  width: 75%;
}

.line-item-big {
  width: 100%;
}

.default-nav {
  background-color: white;
  padding: 40px 20px;
  cursor: pointer;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-nav:hover {
  background-color: #eee;
  color: #333;
}

.active-nav {
  background-color: #1B9C85;
  color: white;
}

.input-default {
  background: none;
  border-radius: none;
}

.input-default:focus, .input-default:focus-visible {
  border: 1px solid #1B9C85!important;
  box-shadow: none!important;
}

.social-icon {
  padding: 4px;
  border: 1px solid #333;
  border-radius: 4px;
  margin: 0 4px;
}

.photo-container {
  display: flex;
  justify-content: center;
}

.signature-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.btn-generate-signature {
  height: 40px;
  /* margin-bottom: 16px; */
  cursor: pointer;
}

.btn-default {
  background-color: #1B9C85;
  color: white;
  padding: 8px 20px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  max-height: 70px;
}

.logo-sidebar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 70px;
}

.site-name {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.btn-chrome-container {
  margin-right: 10px;
  color: #333333;
  font-size: 0.85em;
  border: 1px solid #1a9c86;
  border-radius: 4px;
  transition: 0.25s;
}

.btn-chrome-container:hover {
  background-color: #1a9c86;
  color: white;
  transition: 0.25s;
}

.btn-chrome {
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.rating-container {
  margin-top: 20px;
}