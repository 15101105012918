.scaling-text {
    display: inline-block;
    animation: scaleUpDown 1s ease-in-out infinite;
}
  
@keyframes scaleUpDown {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.tab-header {
    width: 100%;
}