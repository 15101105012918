.full-stars {
    text-align: center;
  }
  
  .full-stars .rating-group {
    display: inline-flex;
  }
  
  .full-stars input {
    position: absolute;
    left: -9999px;
  }
  
  .full-stars label {
    margin: 0;
    cursor: pointer;
  }
  
  .full-stars label a svg {
    margin: 2px;
    height: 30px;
    width: 30px;
    fill: #ff8400;
    transition: fill 0.3s;
  }
  
  /* Highlight stars when an input is checked */
  .full-stars input:checked + label a svg,
  .full-stars input:checked ~ input + label a svg {
    fill: #ffc711;
  }
  
  /* Highlight stars on hover */
  .full-stars label:hover a svg,
  .full-stars label:hover ~ label a svg {
    fill: #ffc711;
  }
  