.welcome-container {
  width: 100%;
  background-color: #ffffff;
  padding-top: 30px;
  padding-bottom: 30px;  
}

.welcome-content-container {
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  max-width: 800px;
}

.welcome-title {
  margin-bottom: 60px;
  font-size: 60px;
  color: #000;
  font-weight: 900;
}

.step-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 50px 0;
  color: #333;
}

.step-content {
  font-weight: 900;
  color: rgba(80, 80, 80, 1.0);
  margin-bottom: 20px;
}

.img-container {
  max-width: 100%;
}

#confettiContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.confetti {
  position: absolute;
  width: 10px;
  height: 20px;
  background-color: #FFC700;
  opacity: 0;
  animation: fall 4s forwards;
}

@keyframes fall {
  0% {
      transform: translateY(0);
      opacity: 1;
  }
  100% {
      transform: translateY(100vh);
      opacity: 0;
  }
}