.delete-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.delete-page-title {
    margin-bottom: 20px;
    font-weight: 100!important;
    color: #000;
    font-weight: 900;
}